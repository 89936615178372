// Error components must be Client Components
"use client"

import { useEffect } from "react"
import { ErrorFeedback } from "src/modules/common/components/ErrorBoundaryFallback"
import { ErrorBoundaryProps } from "src/types/page"
import { reportError } from "src/utilities/error"

export default function ErrorPage({ error }: ErrorBoundaryProps) {
  useEffect(() => {
    reportError("[countryCode]/error page was shown", error)
  }, [error])

  return <ErrorFeedback />
}
